import { FC, ReactNode } from "react";

import { ButtonProps as MuiButtonProps } from "@mui/material";
import { Button as MuiButton } from "ui/Button";
import { Typography } from "ui/Typography";

import { SxProps } from "@mui/material";
import { styles } from "./styles";

export interface ButtonProps extends MuiButtonProps {
  variantType?: "primary" | "secondary" | "thirdly";
  buttonTitle: string | ReactNode;
  primaryBgColor?: string;
  secondaryTextColor?: string;
  thirdlyBgColor?: string;
}

export const Button: FC<ButtonProps> = ({
  variantType = "primary",
  buttonTitle,
  primaryBgColor,
  secondaryTextColor,
  thirdlyBgColor,
  ...rest
}) => {
  const buttonStyle = (variantType: string): SxProps => {
    if (variantType === "secondary") {
      return {
        ...styles.button,
        ...styles.buttonSecondary,
        ".MuiTypography-root": {
          background: secondaryTextColor,
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
        ...rest.sx,
      } as SxProps;
    }
    if (variantType === "thirdly") {
      return {
        ...styles.button,
        ...styles.buttonThirdly,
        background: thirdlyBgColor,
        ...rest.sx,
      } as SxProps;
    }
    return {
      ...styles.button,
      ...styles.buttonPrimary,
      background: primaryBgColor,
      ...rest.sx,
    } as SxProps;
  };

  return (
    <MuiButton {...rest} sx={buttonStyle(variantType)} disableRipple>
      {typeof buttonTitle === "string" ? (
        <Typography variant="buttonTextSmall" sx={styles.buttonTitle}>{buttonTitle}</Typography>
      ) : (
        buttonTitle
      )}
    </MuiButton>
  );
};
