import arrowRight from "assets/arrow.svg";
import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    ".date-picker": {
      width: "min-content",
      height: "min-content",
    },
    ".rdrStartEdge, .rdrEndEdge": {
      color: "transparent !important",
      height: "32px !important",
      width: "32px !important",
      left: "50% !important",
      top: "50% !important",
      transform: "translate(-50%, -50%) !important",
      background: basicTheme.palette.gradientsPalette.gradient5,
      borderRadius: "100% !important",
    },
    ".rdrStartEdge:not(.rdrEndEdge)": {
      "&:before": {
        content: "''",
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "1px",
        right: "-12px",
        bottom: "0",
        background: basicTheme.palette.mainPalette.primaryText,
      },
      "&:after": {
        content: "''",
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "1px",
        right: "-12px",
        top: "0",
        background: basicTheme.palette.mainPalette.primaryText,
      },
    },
    ".rdrEndEdge:not(.rdrStartEdge)": {
      "&:before": {
        content: "''",
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "1px",
        left: "-12px",
        bottom: "0",
        background: basicTheme.palette.mainPalette.primaryText,
      },
      "&:after": {
        content: "''",
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "1px",
        left: "-12px",
        top: "0",
        background: basicTheme.palette.mainPalette.primaryText,
      },
    },
    ".rdrDay": {
      width: "calc(100% / 7) !important",
      height: "40px !important",
      position: "relative !important",
      ".rdrDayNumber": {
        span: {
          typography: basicTheme.typography.buttonTextSmall,
          lineHeight: 1.00,
        },
      },
      "&:not(.rdrDayDisabled, .rdrDayPassive)": {
        ".rdrDayNumber": {
          span: {
            color: `${basicTheme.palette.mainPalette.primaryText} !important`,
          },
        },
      },
      "&:has(.rdrStartEdge, .rdrEndEdge)": {
        ".rdrDayNumber": {
          span: {
            color: `${basicTheme.palette.mainPalette.white} !important`,
          },
        },
      },
    },
    ".rdrDayPassive": {
      pointerEvents: "auto",
      "& > span > span": {
        color: `${basicTheme.palette.mainPalette.placeholderText} !important`,
      },
      ".rdrInRange": {
        display: "flex !important",
      },
      ".rdrStartEdge": {
        display: "flex !important",
      },
      ".rdrEndEdge": {
        display: "flex !important",
      },
    },
    ".rdrInRange": {
      borderRadius: "0 !important",
      color: "transparent !important",
      height: "24px !important",
      width: "100% !important",
      left: "50% !important",
      top: "50% !important",
      transform: "translate(-50%, -50%) !important",
      "&:before": {
        content: "''",
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "1px",
        left: "0",
        bottom: "-4px",
        background: basicTheme.palette.gradientsPalette.gradient5,
      },
      "&:after": {
        content: "''",
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "1px",
        left: "0",
        top: "-4px",
        background: basicTheme.palette.gradientsPalette.gradient5,
      },
    },
    ".rdrDayDisabled": {
      backgroundColor: "transparent !important",
      "&:has(.rdrStartEdge, .rdrEndEdge)": {
        ".rdrDayNumber": {
          span: {
            color: `${basicTheme.palette.mainPalette.secondaryGrey} !important`,
          },
        },
      },
      ".rdrDayNumber": {
        span: {
          color: `${basicTheme.palette.mainPalette.secondaryGrey} !important`,
        },
      },
      ".rdrStartEdge": {
        background: "transparent !important",
      },
      ".rdrEndEdge": {
        background: "transparent !important",
      },
    },
    ".rdrDayToday": {
      "& > .rdrDayNumber > span": {
        borderBottom: "1px solid",
        borderColor: basicTheme.palette.accentPalette.blue3,
      },
      "& > .rdrDayNumber > span:after": {
        backgroundColor: "transparent !important",
      },
      "&:has(.rdrStartEdge, .rdrEndEdge)": {
        "& > .rdrDayNumber > span": {
          border:"none"
        },
      },
    },
    ".rdrMonthAndYearWrapper": {
      position: "absolute !important",
      width: "100% !important",
      padding: "0 !important",
      height: "auto !important",
      mt: "3px",
      ".rdrMonthAndYearPickers": {
        display: "none !important",
      },
    },
    ".rdrCalendarWrapper": {
      position: "relative !important",
      width: "100%",
      [basicTheme.breakpoints.up("tablet")]: {
        width: 336,
        ml: "auto",
      }
    },
    ".rdrMonthName": {
      textAlign: "center !important",
      color: `${basicTheme.palette.mainPalette.primaryText} !important`,
      typography: basicTheme.typography.buttonTextSmall,
    },
    ".rdrNextPrevButton": {
      background: "transparent !important",
      i: {
        border: "none !important",
        position: "relative",
      },
    },
    ".rdrPprevButton > i::before": {
      content: `url(${arrowRight})`,
      width: 16,
      height: 16,
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    ".rdrNextButton > i::before": {
      content: `url(${arrowRight})`,
      width: 16,
      height: 16,
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    ".rdrWeekDay": {
      color: `${basicTheme.palette.mainPalette.placeholderText} !important`,
      typography: basicTheme.typography.caption,
    },
    ".rdrDayNumber": {
      height: "auto !important",
      left: "50% !important",
      top: "50% !important",
      transform: "translate(-50%, -50%) !important",
    },
    ".rdrWeekDays": {
      mt: "10px",
      mb: "16px",
    },
    ".rdrDateRangePickerWrapper": {
      width: "100% !important",
      flexDirection: "column",
      [basicTheme.breakpoints.up("tablet")]: {
        flexDirection: "row",
      },
    },
    ".rdrMonth": {
      width: "100% !important",
      p: "0px !important",
    },
    ".rdrDefinedRangesWrapper": {
      width: "100%",
      maxWidth: `calc(100% - 48px)`,
      borderRight: "none",
      position: "absolute",
      top: 24,
      left: 24,
      borderBottom: "1px solid",
      borderBottomColor: basicTheme.palette.mainPalette.secondaryGrey,
      pb: 6,
      [basicTheme.breakpoints.up("tablet")]: {
        width: 130,
        pb:0,
        pr: 5,
        mr: 5,
        borderRight: "1px solid",
        borderRightColor: basicTheme.palette.mainPalette.secondaryGrey,
        borderBottom: "none",
        height: "calc(100% - 48px)",
      },
    },
    ".rdrStaticRanges": {
      rowGap: 4,
      flexWrap: "wrap",
      flexDirection: "row",
      gap: 2.75,
      maxWidth: `100%`,
      [basicTheme.breakpoints.up("tablet")]: {
        gap: 4,
      },
    },
    ".rdrStaticRange": {
      border: "1px solid",
      borderColor: basicTheme.palette.mainPalette.placeholderText,
      borderRadius: 2,
      background: "transparent",
      width: "fit-content",
      minWidth: "min-content",
      overflow: "hidden",
      height: "28px",
      span: {
        typography: basicTheme.typography.buttonTextSmall,
        color: basicTheme.palette.mainPalette.placeholderText,
        whiteSpace: "nowrap",
        px: 2.5,
        py: 2,
        lineHeight: 1.05,
      },
      "&.rdrStaticRangeSelected": {
        borderColor: basicTheme.palette.mainPalette.primaryBlack,
        background: basicTheme.palette.mainPalette.primaryGrey,
        span: {
          color: basicTheme.palette.mainPalette.primaryBlack,
        },
        "&:hover": {
          background: basicTheme.palette.mainPalette.primaryGrey,
          borderColor: basicTheme.palette.mainPalette.primaryBlack,
          color: basicTheme.palette.mainPalette.primaryBlack,
          span: {
            color: basicTheme.palette.mainPalette.primaryBlack,
          },
        },
      },
      "&:hover": {
        background: "transparent !important",
        bgcolor: "transparent !important",
        border: "1px solid",
        borderColor: basicTheme.palette.mainPalette.placeholderText,
        borderRadius: 2,
      },
    },
    ".rdrDayStartPreview": {
      display: "none !important",
    },
    ".rdrDayInPreview": {
      display: "none !important",
    },
    ".rdrDayEndPreview": {
      display: "none !important",
    },
    ".rdrInputRanges": {
      display: "none !important",
    },
  },
};
