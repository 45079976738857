import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    width: 200,
    borderRight: "1px solid",
    borderColor: basicTheme.palette.mainPalette.lightSecondaryGrey,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 100,
    height: "100vh",
    bgcolor: basicTheme.palette.mainPalette.white,
    [basicTheme.breakpoints.up("laptop")]: {
      position: "relative",
    },
  },
  rootContainer: {
    position: "relative",
  },
  blackBackground: {
    position: "absolute",
    zIndex: 100,
    content: "''",
    display: "flex",
    bgcolor: "rgba(0, 0, 0, 0.5)",
    width: "100vw",
    left: 0,
    top: 0,
    height: "100vh",
  },
  shortRoot: {
    width: 64,
    maxWidth: 64,
    height: 65,
    zIndex: 10,
    borderBottom: `1px solid ${basicTheme.palette.mainPalette.lightSecondaryGrey}`,
    [basicTheme.breakpoints.up("laptop")]: {
      height: "100vh",
      position: "relative",
      borderBottom: "none",
    },
  },
  headNavigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 8,
    px: 5,
    pt: 5,
  },
  shortHeadNavigation:{
    px: 2,
  },
  fullLogo: {
    cursor: "pointer",
    "&&": {
      width: 121,
      height: 21,
    },
  },
  logo: {
    cursor: "pointer",
    ml:1,
    "&&": {
      width: 16.268,
      height: 18.989,
    },
  },
  icon: {
    transform: "rotate(180deg)",
    color: basicTheme.palette.mainPalette.primaryBlack,
  },
  openIcon: {
        color: basicTheme.palette.mainPalette.primaryBlack,
  },
  navigationButton: {
    mt: "auto",
  },
  contentContainer: {
    px: 5,
    pb: 5,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  shortContentContainer: {
    px: 2,
  },
};
